import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import theme from "src/components/theme";

const SectionContainer = styled.section`
  min-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SectionContent = styled.div`
  min-width: 320px;
  max-width: 1280px;
  width: 100%;
  padding: 60px 16px;

  @media (min-width: ${theme.mobileBreakpoint}) {
    padding: 80px 40px;
  }
`;

const Section = ({ children, ...otherProps }) => (
  <SectionContainer {...otherProps}>
    <SectionContent>{children}</SectionContent>
  </SectionContainer>
);

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Section;
