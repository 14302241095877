import React from "react";
import Section, { SectionContent } from "./sections/Section";
import styled from "styled-components";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import { H2, Text } from "../components/Common";
import theme from "src/components/theme";

const MonitorsGridContainer = styled(Section)`
  ${SectionContent} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ${H2} {
    margin: 0;

    @media (min-width: ${theme.mobileBreakpoint}) {
      margin: 40px 0;
    }
  }
`;

const MonitorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
`;

const Monitor = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  min-width: 300px;
  max-width: 420px;

  margin: 20px 0;

  @media (min-width: ${theme.mobileBreakpoint}) {
    margin: 30px 20px;
  }
`;

const IconContainer = styled.div`
  border-radius: 8px;
  border: 2px solid ${theme.monitorIconBorder};
  flex: none;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MonitorDescriptionContainer = styled(Text)`
  margin-left: 20px;
`;

const MonitorTitle = styled(Text)`
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: ${theme.titleColor};
`;

const MonitorsGrid = ({ currentMonitor, id }) => (
  <StaticQuery
    query={graphql`
      query AllMonitorsQuery {
        allMarkdownRemark(filter: { fileAbsolutePath: { regex: "//pages/monitors/.*.md/" } }) {
          edges {
            node {
              frontmatter {
                path
                title
                description
                monitor
                order
                icon {
                  childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 24, height: 24)
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const monitorsToDispaly = data.allMarkdownRemark.edges.filter(
        edge => edge.node.frontmatter.monitor !== currentMonitor,
      );

      if (monitorsToDispaly.length === 0) {
        return null;
      }

      return (
        <MonitorsGridContainer id={id}>
          <H2>{currentMonitor ? "Explore other monitors" : "Explore monitors"}</H2>
          <MonitorsContainer>
            {monitorsToDispaly
              .sort((a, b) => a.node.frontmatter.order - b.node.frontmatter.order)
              .map((edge, index) => (
                <Monitor key={`monitor${index}`}>
                  <IconContainer>
                    <Img fixed={edge.node.frontmatter.icon.childImageSharp.fixed} />
                  </IconContainer>
                  <MonitorDescriptionContainer>
                    <MonitorTitle>{edge.node.frontmatter.title}</MonitorTitle>
                    {edge.node.frontmatter.description}
                  </MonitorDescriptionContainer>
                </Monitor>
              ))}
          </MonitorsContainer>
        </MonitorsGridContainer>
      );
    }}
  />
);

MonitorsGrid.propTypes = {
  id: PropTypes.string,
  currentMonitor: PropTypes.string,
};

export default MonitorsGrid;
