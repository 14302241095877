import React from "react";
import styled from "styled-components";
import Section, { SectionContent } from "../sections/Section";
import { H2, TitleLinkButton, AccentLinkButton } from "../Common";
import SVGBigDuck from "../../svg/images/big-duck.svg";
import theme from "src/components/theme";
import { openChat } from "src/utils";

const ContactSectionContainer = styled(Section)`
  background-color: ${theme.accentColor};

  ${SectionContent} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    max-width: 1220px;

    @media (min-width: ${theme.mobileBreakpoint}) {
      flex-direction: column;
    }

    @media (min-width: ${theme.readableMaxWidth}) {
      flex-direction: row;
    }

    @media (min-width: ${theme.demoBreakpoint}) {
      padding: 0;
    }
  }
`;

const DuckImage = styled.svg.attrs({
  viewBox: SVGBigDuck.viewBox,
})`
  display: none;

  @media (min-width: ${theme.demoBreakpoint}) {
    display: inline;
    align-self: flex-end;
    width: 305px;
    min-width: 305px;
  }
`;

const SectionTitle = styled(H2)`
  text-align: center;

  @media (min-width: ${theme.mobileBreakpoint}) {
    text-align: center;
  }
  @media (min-width: ${theme.readableMaxWidth}) {
    text-align: left;
  }
`;

const SectionText = styled.p`
  max-width: 480px;
  opacity: 0.8;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.44;
  color: ${theme.titleColor};
  text-align: center;

  @media (min-width: ${theme.mobileBreakpoint}) {
    text-align: center;
  }

  @media (min-width: ${theme.readableMaxWidth}) {
    text-align: left;
    margin-right: 20px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  @media (min-width: ${theme.mobileBreakpoint}) {
    flex-direction: row;
    justify-content: center;
  }

  @media (min-width: ${theme.readableMaxWidth}) {
    margin-top: 0;
  }
`;

const StartNowButton = styled(TitleLinkButton)`
  max-width: 380px;
  font-size: 22px;
  width: 160px;
  height: 52px;
  margin-top: 20px;
  border-radius: 6px;
  background-color: solid 3px ${theme.titleColor};

  @media (min-width: ${theme.mobileBreakpoint}) {
    margin-top: 0;
    margin-left: 20px;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  margin: 0 auto;

  @media (min-width: ${theme.demoBreakpoint}) {
    padding: 80px 20px;
  }
`;

const TextContainer = styled.div`
  @media (min-width: ${theme.readableMaxWidth}) {
    display: flex;
    align-items: center;
  }
`;

const ContactButton = styled(AccentLinkButton)`
  width: 160px;
  height: 52px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.05);
  border: solid 1px rgba(255, 255, 255, 0.2);
`;

const ContactSection = () => {
  return (
    <ContactSectionContainer>
      <DuckImage>
        <use xlinkHref={`#${SVGBigDuck.id}`} />
      </DuckImage>
      <ContentContainer>
        <SectionTitle>We love dialogue</SectionTitle>
        <TextContainer>
          <SectionText>
            Maybe you’d like extra support. Maybe you have unique environment-related requirements you want to discuss.
            Or maybe you simply have some questions. Ask away! Let us know what’s on your mind.
          </SectionText>
          <ButtonsContainer>
            <ContactButton onClick={openChat}>Contact Us</ContactButton>
            <StartNowButton href="/signup">Start Trial</StartNowButton>
          </ButtonsContainer>
        </TextContainer>
      </ContentContainer>
    </ContactSectionContainer>
  );
};

export default ContactSection;
